<template>
  <b-card
    header-bg-variant="warning"
    header-class="py-25 px-1 rounded_header"
    class="rounded-lg mb-50 mb-lg-1"
    no-body
  >
    <template #header>
      <div
        class="w-100 d-flex-between text-white cursor-pointer"
        @click="toggleVisibleTrips"
      >
        <span :class="`fw-800 ${isMobileView ? 'font-small-4' : ''}`">
          Giỏ vé
        </span>

        <div class="p-25">
          <IconSvg
            :src="require('@icons/triangle-down.svg')"
            blank-color="#ccc"
            class="py-25 rotate_class text-white"
            :style="{transform: rotateIconArrow, width: '18px'}"
          />
        </div>
      </div>
    </template>

    <!-- ANCHOR - CHI TIẾT CHUYẾN -->
    <div
      v-for="(trip, tripIndex) in getSearchPayloadArray"
      :key="tripIndex"
      :class="`cursor-pointer ${tripIndex === tabIndex ? 'border-left-5-warning' : 'border-left-5-white'}`"
      style="border-bottom: 1px dashed #dddddd;"
    >
      <b-card
        :id="`tripItem-${tripIndex}`"
        class="mb-25"
        header-class="p-50"
        body-class="p-50"
        no-body
      >
        <template #header>
          <div @click.stop="handleSetTabIndex(tripIndex)">
            <div class="w-100 d-flex-between">
              <div class="d-flex w-100">
                <div class="mr-50 d-flex-center">
                  <b-avatar
                    size="27"
                    :variant="tripIndex === tabIndex ? 'warning' : 'secondary'"
                  >
                    {{ tripIndex + 1 }}
                  </b-avatar>
                </div>
                <div class="d-flex-center justify-content-start w-100">
                  <div
                    class="text-heading-4"
                    style="line-height: 24px; font-weight: 800;"
                    :class="tripIndex === tabIndex ? 'text-warning' : 'text-secondary'"
                  >
                    {{ getAirportName(trip.departure) }}
                  </div>
                  <div class="d-flex-center">
                    <IAmIcon
                      v-if="isTypeRT"
                      icon="swap"
                      class="mx-25 d-flex-center"
                      size="20px"
                      :class="`${tripIndex === tabIndex ?'text-warning' : 'text-secondary'}`"
                    />
                    <feather-icon
                      v-else
                      size="20"
                      icon="ArrowRightIcon"
                      :class="`mx-25 ${tripIndex === tabIndex ?'text-warning' : 'text-secondary'}`"
                      style="stroke-width: 3;"
                    />
                  </div>
                  <div
                    class="text-heading-4"
                    style="line-height: 24px; font-weight: 800;"
                    :class="tripIndex === tabIndex ? 'text-warning' : 'text-secondary'"
                  >
                    {{ getAirportName(trip.arrival) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="font-small-4 fw-800 ml-50 text-dark">
              <!-- <div v-if="resolveSelectedTrip?.trips[tripIndex]">
                {{ convertISODateTimeLangVN(resolveSelectedTrip?.trips[tripIndex].departure.at, resolveSelectedTrip?.trips[tripIndex].departure.timezone).shortDay }}
                <b-img
                  blank-color="#ccc"
                  src="@icons/column.svg"
                  class="py-0 px-50"
                />
                {{ convertISODateTime(resolveSelectedTrip?.trips[tripIndex].departure.at, resolveSelectedTrip?.trips[tripIndex].departure.timezone).time }} - {{ convertISODateTime(resolveSelectedTrip?.trips[tripIndex].arrival.at, resolveSelectedTrip?.trips[tripIndex].arrival.timezone).time }}
              </div>
              <div
                v-else
                :class="`${tripIndex === tabIndex ? 'text-dark' : 'text-muted'}`"
              > -->
              {{ convertISODateTimeLangVN(trip.departDate).shortDay }}
              <!-- </div> -->
            </div>
          </div>
        </template>

        <b-collapse
          :id="`tripItem-${tripIndex}`"
          v-model="isVisibleTrips[tripIndex]"
          class="ml-75"
          role="tabpanel"
        >
          <!-- ANCHOR - THÔNG TIN VÉ ĐÃ CHỌN -->
          <template v-if="!isEmpty(stateTicketSelectedArray[tripIndex])">
            <div
              v-for="(ticket, indexTicket) of stateTicketSelectedArray[tripIndex]"
              :key="indexTicket"
            >
              <!-- {{ indexTicket }} -->
              <CardTicketItem
                :ticket="ticket"
                :index="tripIndex"
              />
            </div>
          </template>

          <i
            v-else
            class="text-danger"
          >
            Chưa chọn vé
          </i>
        </b-collapse>
      </b-card>
    </div>

    <!-- ANCHOR - TỔNG TIỀN -->
    <div class="text-airline text-right mr-75 my-50">
      <span
        class="mr-25 mr-md-75 text-nowrap font-weight-bold"
        :class="isMobileView ? 'font-small-4' : ''"
      >
        {{ $t('flight.Total') }}
      </span>
      <span
        class="text-nowrap fw-800"
        :class="isMobileView ? 'font-medium-1': 'font-medium-4'"
      >
        {{ formatCurrency(resolveTotalPrice) }}
      </span>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BRow, BCol, BCollapse, BButton,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  watch,
  onMounted, watchEffect,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import {
  convertISODateTime,
  convertISODateTimeLangVN,
  formatCurrency,
  toHoursAndMinutes,
} from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BAvatar,
    BCollapse,

    BImg,
    BRow,
    BCol,
    BButton,

    CardTicketItem: () => import('./CardTicketItem.vue'),
  },
  setup() {
    const {
      tabIndex,
      stateTicketSelectedArray,

      getSearchPayloadArray,
      getListTrainStation,

      handleSetTabIndex,
    } = useTrainHandle()

    // RT => show icon
    const isTypeRT = computed(() => getSearchPayloadArray.value?.length === 2)

    function getAirportName(code) {
      const station = getListTrainStation.value.find(it => it.code === code)
      return station?.name || code
    }

    const resolveTotalPrice = computed(() => {
      const totalPrice = stateTicketSelectedArray.value.reduce((acc, item) => {
        item.forEach(ticket => {
          acc += ticket.totalPrice
        })
        return acc
      }, 0)

      return totalPrice * 1000
    })

    // SECTION: COLLAPSE TRIPs ==========================
    const isVisibleTrips = ref([])
    const firstTime = ref(true)
    const rotateIconArrow = computed(() => isVisibleTrips.value.every(Boolean) ? 'rotate(0deg)' : 'rotate(-180deg)')
    watchEffect(() => {
      if (firstTime.value && getSearchPayloadArray.value.length) {
        isVisibleTrips.value = getSearchPayloadArray.value?.map(() => true)
        firstTime.value = false
      }
    })
    // toggle all
    const toggleVisibleTrips = () => {
      isVisibleTrips.value = getSearchPayloadArray.value.map(() => !isVisibleTrips.value.every(Boolean))
    }
    onMounted(() => {
      isVisibleTrips.value = getSearchPayloadArray.value.map(() => true)
    })
    // !SECTION: END COLLAPSE TRIPs ==========================

    return {
      isEmpty,
      tabIndex,
      isTypeRT,
      getSearchPayloadArray,
      convertISODateTime,
      convertISODateTimeLangVN,
      formatCurrency,
      toHoursAndMinutes,

      rotateIconArrow,
      isVisibleTrips,
      toggleVisibleTrips,

      getAirportName,
      handleSetTabIndex,

      stateTicketSelectedArray,
      resolveTotalPrice,
    }
  },
}
</script>

<style lang="scss" scoped>
.rounded_header {
  border-radius: 8px 8px 0 0 !important; // trên trái, trên phải, dưới trái, dưới phải
}

.header_class_toggle {
  border-left: 7px #999999 !important;

  &.active {
    border-left: 7px #efad02 !important;
  }
}

.rotate_class {
  transition: transform 0.3s ease;
}
</style>
