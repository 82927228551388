import { render, staticRenderFns } from "./CartTicket.vue?vue&type=template&id=15bf1ef6&scoped=true"
import script from "./CartTicket.vue?vue&type=script&lang=js"
export * from "./CartTicket.vue?vue&type=script&lang=js"
import style0 from "./CartTicket.vue?vue&type=style&index=0&id=15bf1ef6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15bf1ef6",
  null
  
)

export default component.exports