var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "rounded-lg mb-50 mb-lg-1",
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "py-25 px-1 rounded_header",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex-between text-white cursor-pointer",
          on: {
            "click": _vm.toggleVisibleTrips
          }
        }, [_c('span', {
          class: "fw-800 ".concat(_vm.isMobileView ? 'font-small-4' : '')
        }, [_vm._v(" Giỏ vé ")]), _c('div', {
          staticClass: "p-25"
        }, [_c('IconSvg', {
          staticClass: "py-25 rotate_class text-white",
          style: {
            transform: _vm.rotateIconArrow,
            width: '18px'
          },
          attrs: {
            "src": require('@icons/triangle-down.svg'),
            "blank-color": "#ccc"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.getSearchPayloadArray, function (trip, tripIndex) {
    return _c('div', {
      key: tripIndex,
      class: "cursor-pointer ".concat(tripIndex === _vm.tabIndex ? 'border-left-5-warning' : 'border-left-5-white'),
      staticStyle: {
        "border-bottom": "1px dashed #dddddd"
      }
    }, [_c('b-card', {
      staticClass: "mb-25",
      attrs: {
        "id": "tripItem-".concat(tripIndex),
        "header-class": "p-50",
        "body-class": "p-50",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.handleSetTabIndex(tripIndex);
              }
            }
          }, [_c('div', {
            staticClass: "w-100 d-flex-between"
          }, [_c('div', {
            staticClass: "d-flex w-100"
          }, [_c('div', {
            staticClass: "mr-50 d-flex-center"
          }, [_c('b-avatar', {
            attrs: {
              "size": "27",
              "variant": tripIndex === _vm.tabIndex ? 'warning' : 'secondary'
            }
          }, [_vm._v(" " + _vm._s(tripIndex + 1) + " ")])], 1), _c('div', {
            staticClass: "d-flex-center justify-content-start w-100"
          }, [_c('div', {
            staticClass: "text-heading-4",
            class: tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary',
            staticStyle: {
              "line-height": "24px",
              "font-weight": "800"
            }
          }, [_vm._v(" " + _vm._s(_vm.getAirportName(trip.departure)) + " ")]), _c('div', {
            staticClass: "d-flex-center"
          }, [_vm.isTypeRT ? _c('IAmIcon', {
            staticClass: "mx-25 d-flex-center",
            class: "".concat(tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary'),
            attrs: {
              "icon": "swap",
              "size": "20px"
            }
          }) : _c('feather-icon', {
            class: "mx-25 ".concat(tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary'),
            staticStyle: {
              "stroke-width": "3"
            },
            attrs: {
              "size": "20",
              "icon": "ArrowRightIcon"
            }
          })], 1), _c('div', {
            staticClass: "text-heading-4",
            class: tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary',
            staticStyle: {
              "line-height": "24px",
              "font-weight": "800"
            }
          }, [_vm._v(" " + _vm._s(_vm.getAirportName(trip.arrival)) + " ")])])])]), _c('div', {
            staticClass: "font-small-4 fw-800 ml-50 text-dark"
          }, [_vm._v(" " + _vm._s(_vm.convertISODateTimeLangVN(trip.departDate).shortDay) + " ")])])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-collapse', {
      staticClass: "ml-75",
      attrs: {
        "id": "tripItem-".concat(tripIndex),
        "role": "tabpanel"
      },
      model: {
        value: _vm.isVisibleTrips[tripIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.isVisibleTrips, tripIndex, $$v);
        },
        expression: "isVisibleTrips[tripIndex]"
      }
    }, [!_vm.isEmpty(_vm.stateTicketSelectedArray[tripIndex]) ? _vm._l(_vm.stateTicketSelectedArray[tripIndex], function (ticket, indexTicket) {
      return _c('div', {
        key: indexTicket
      }, [_c('CardTicketItem', {
        attrs: {
          "ticket": ticket,
          "index": tripIndex
        }
      })], 1);
    }) : _c('i', {
      staticClass: "text-danger"
    }, [_vm._v(" Chưa chọn vé ")])], 2)], 1)], 1);
  }), _c('div', {
    staticClass: "text-airline text-right mr-75 my-50"
  }, [_c('span', {
    staticClass: "mr-25 mr-md-75 text-nowrap font-weight-bold",
    class: _vm.isMobileView ? 'font-small-4' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Total')) + " ")]), _c('span', {
    staticClass: "text-nowrap fw-800",
    class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-4'
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resolveTotalPrice)) + " ")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }